<template>
    <div class="goodsList">
        <div class="res">
            <span class="font-color">！提示</span>：可通过列表右下角的切换按钮,来控制显示用户的品牌列表中的招商价
        </div>
        <div class="list">
            <div class="item acea-row" v-for="(item,index) in list" :key="index" >
                <div class="image">
                    <img :src="item.image" />
                </div>
                <div class="info">
                    <div class="name Ellipses1">{{item.store_name}}</div>
                    <div class="price">
                        招商价：<span class="font-color">¥{{item.store_price}}</span>
                    </div>
                </div>
                <div class="switsh">
                    <ToggleSwitch ref="ToggleSwitch" :speed='item.is_show_price?false:true' @change="isShow(item.id,item.is_show_price?false:true)"></ToggleSwitch>
                </div>
            </div>
        </div>
        <div class="Loading" v-if="loading">
            <Loading size="24px">加载中...</Loading>
            </div>
            <div class="Loading" v-if="finished">没有更多了~</div>
        </div>
</template>

<script>
    import { getUserShowPrice,setUserShowPrice } from "@api/tasting";
    import ToggleSwitch from '../components/ToggleSwitch.vue';
    import { Icon, Toast, Tab  ,Loading } from "vant";
    export default {
        name: "goodsList",
        data: function() {
            return {
                keyword:'',
                page:0,
                limit:20,
                list:[],
                loading:false,
                finished:false,
                listshow:false,
                count:0,
                uid:0,
            };
        },
        components: {
            Icon, Toast, Tab ,Loading , ToggleSwitch
        },
        created: function() {
            this.uid=this.$route.query.uid;
        }, 
        mounted: function() {
            this.Loadmore();
            var that=this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            isShow:function(id,is_show){
                Toast.loading({
                    duration: 0,
                    message: '设置中',
                    forbidClick: true,
                });
                setUserShowPrice({
                    uid:this.uid,
                    is_show:is_show,
                    product_id:id,
                }).then(res=>{
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });
                     Toast.clear();
                }).catch(err=>{
                    Toast.success({
                        message: err.msg,
                        forbidClick: true,
                    });
                     Toast.clear();
                })
            },
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            query(){
                this.getKeyword();
            },
            setdata(){
                this.loading = false;
                this.finished = false;
                this.page = 0;
                this.limit = 20;
                this.list=[];
                this.list_lengths=0;
            },
            setReset(){
                this.listshow = false;
                this.keyword = '';
                Toast.loading({
                    duration: 0,
                    message: '重置中...',
                    forbidClick: true,
                });
                this.setdata();
                this.Loadmore();
                Toast.clear();
            },
            Loadmore: function() {
                    if(this.loading) return;
                    if(this.finished) return;
                    this.getStoreUserLists();
            },
           getStoreUserLists:function(){
               this.loading = true;
               getUserShowPrice({
                   page:this.page,
                   limit:this.limit,
                   keyword:this.keyword,
                   uid:this.uid
               }).then(res => {
                    this.count = res.data.count;
                    var listdata = res.data.list;
                    listdata.forEach((item)=>{
                        this.list.push(item);
                    })
                    this.loading = false;
                    this.page++;
                    this.list_lengths += listdata.length;
                    if(listdata.length<2){
                        this.finished = true;
                    }
               }).catch(err => {
                    this.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getKeyword(){
                getUserShowPrice({
                    page:this.page,
                    limit:this.limit,
                    keyword:this.keyword,
                    uid:this.uid
                }).then(res => {
                    this.setdata();
                    var listdata = res.data.list;
                    listdata.forEach((item)=>{
                        this.list.push(item);
                    })
                    this.listshow = true;
                }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            }
    },
           
}

</script>

<style scoped lang="scss">
.goodsList{
    .res{
        background-color: #fff;
        padding: 0.1rem;
        font-weight: 600;
    }
    .item{
        background-color: #fff;
        margin: 0.12rem;
        border-radius: 0.1rem;
        padding: 0.1rem;
        position: relative;

    }
    .image{
        width: 1.4rem;
        height: 1.4rem;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .info{
        width: 70%;
        .name{
            font-weight: 600;
            font-size: 0.3rem;
        }
        .price{
                font-size: 0.25rem;
                 margin-top: 0.1rem;
        }
    }
    .switsh{
        position: absolute;
        bottom: 0.1rem;
        right: 0.1rem;
    }
}
.Loading{
    text-align: center;
    margin-top: 0.1rem;
    color: #ccc;
}

</style>